/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface Props {
  description: string;
  lang: string;
  meta: any[];
  hasHeroImage?: boolean;
  title: string;
  titleTemplate?: string;
}
function SEO({ description, lang, meta, title, hasHeroImage, titleTemplate }: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  if (!titleTemplate) {
    titleTemplate = `%s | ${site.siteMetadata.title}`
  }
  const metaDescription = description || site.siteMetadata.description;
  console.log('render seo component');
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={
        hasHeroImage
          ? {
            class: "has-hero-image",
          }
          : {}
      }
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
      <link rel="preconnect" href="https://www.google-analytics.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css?family=Merriweather:300i|Roboto:300,400,400i,500,700&display=swap&subset=latin-ext"
        rel="stylesheet"
      />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  hasHeroImage: false,
  description: ``,
  titleTemplate: null
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
